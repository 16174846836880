import { useSettings } from '@backpackjs/storefront';

import React, { useState, useEffect } from 'react';

import { CollectionSearchspringSort } from './CollectionSearchspringSort';
import { CollectionSearchspringFilter } from './CollectionSearchspringFilter';

export function CollectionSearchspringSortFilterBar({
  isSearchResults,
  enabledSort,
  enabledFilters,
  openFilter,
  setOpenFilter,
  selectedSort,
  selectSort,
  sortOptions,
  areOpen,
  setAreOpen,
  filters,
  filterSummary,
  addToSelectedFilters,
  removeFromSelectedFilters,
  clearSelectedFilters,
  swatchesMap,
}) {
  const settings = useSettings();
  const { sticky  } = {
    ...settings?.collection?.filters,
  };

  //Check if select is open
  const [sortIsOpen, setSortIsOpen] = useState(false);
  //Check if filter is open
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  //Check for sticky Promobar
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'lg:sticky is-stuck w-screen lg:w-[calc(100%+2.5rem)] xl:lg:w-[calc(100%+5rem)] bg-offWhite shadow-hairline-b -ml-5 pt-[calc(var(--menu-height)+var(--promobar-height))] lg:pt-[calc(var(--header-height)+var(--promobar-height)+.5px)] lg:-ml-5 xl:-ml-10'
    : 'lg:sticky is-stuck w-screen lg:w-[calc(100%+2.5rem)] xl:lg:w-[calc(100%+5rem)] bg-offWhite shadow-hairline-b pt-[calc(var(--header-height)-var(--promobar-height))] -ml-5 lg:pt-[calc(var(--header-height)+.5px)] lg:-ml-5 xl:-ml-10';

  //Sticky Bar - Target position sticky
  const [stuck, setStuck] = useState(false)
  const ref = React.createRef()
  const stickyClasses = stuck 
    ? stickyTopClass
    : 'w-full'
  const inlineStyles = {
    top: -1,
  }
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] }
    )
    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref])

  return (
    <div ref={ref} style={inlineStyles} className={`filter-panel flex flex-row flex-wrap bg-background ${
      sortIsOpen || filterIsOpen ? '' : 'sticky z-[1]'
    } ${
      sticky ? stickyClasses : ''
    }`} 
    >
      {enabledSort && (
        <div
          className={`order-2 ${
            enabledFilters 
              ? 'w-1/2 md:w-full md:max-w-[11rem]' 
              : 'w-full'
          }`}
        >
          <CollectionSearchspringSort
            isSearchResults={isSearchResults}
            selectedSort={selectedSort}
            selectSort={selectSort}
            sortOptions={sortOptions}
            isOpen={sortIsOpen}
            setIsOpen={setSortIsOpen}
          />
        </div>
      )}
      {enabledFilters && (
        <div className={`order-1 md:flex ${
          enabledSort
            ? 'w-1/2 md:flex-1 md:pr-2.5'
            : 'w-full'
          }`}
        >
          <CollectionSearchspringFilter
            isOpen={filterIsOpen}
            setIsOpen={setFilterIsOpen}
            areOpen={areOpen}
            setAreOpen={setAreOpen}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            filters={filters}
            filterSummary={filterSummary}
            addToSelectedFilters={addToSelectedFilters}
            removeFromSelectedFilters={removeFromSelectedFilters}
            clearSelectedFilters={clearSelectedFilters}
            swatchesMap={swatchesMap}
          />
        </div>
      )}
    </div>
  )
}

CollectionSearchspringSortFilterBar.displayName = 'CollectionSearchspringSortFilterBar';