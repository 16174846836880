import { COLORS, OBJECT_POSITIONS } from '../../settings/common';

export function Schema() {
  return {
    category: 'APP',
    label: 'Iframe',
    key: 'iframe',
    fields: [
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: true,
          fullBleed: true,
          verticalPadding: true,
        },
      },
      {
        label: 'Iframe Settings',
        name: 'iframe',
        component: 'group',
        fields: [
          {
            label: 'Iframe URL',
            name: 'url',
            component: 'text',
            description: 'URL to embed in the iframe',
          },
        ],
      },
    ],
  };
}