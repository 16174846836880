import { useState } from 'react';

import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
  textToHandle,
}) {
  const { imageLinks, links, mainLink, menuItem } = { ...nestedSidebarContent };

  return (
    <div
      id={`${textToHandle(menuItem?.text)}-main-menu`} 
      role="menu"
      className={`scrollbar-hide absolute left-0 top-[.5px] z-[1] h-full w-full bg-background ${
        nestedSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <button
          aria-label="Go back to main menu"
          className="sticky top-0 z-[1] flex h-14 w-full items-center justify-between gap-4 shadow-hairline-b bg-offWhite p-4"
          onClick={() => handleNestedSidebar(null)}
          type="button"
        >
          <Svg
            className="w-4 text-text"
            src="/svgs/chevron-left.svg#chevron-left"
            alt="Back Icon"
            title="Chevron"
            viewBox="0 0 24 24"
          />

          <h3 className="text-nav flex-1 text-left">{menuItem?.text}</h3>
        </button>

        <div>
          <ul className="flex flex-col mb-5" aria-label={`${menuItem?.text} collections`}>
            {links?.map(({ link, links }, index) => {
              const hasContent = links?.length > 0;
              const [isOpen, setIsOpen] = useState(true);
              
              return (
                <li 
                  key={index}
                  role="menuitem"
                  className="flex flex-col min-h-14 w-full shadow-hairline-b"
                >
                  {hasContent ? (
                    <>
                      <button
                        aria-label={`${link?.text} menu`}
                        aria-expanded={!isOpen ? 'true' : 'false'}
                        aria-controls={`${textToHandle(link?.text)}-main-menu`}
                        className={`flex h-14 w-full items-center justify-between gap-5 p-4 ${isOpen ? '' : 'shadow-hairline-b'}`}
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                      >
                        <p className="text-nav flex-1 text-left">
                          {link?.text}
                        </p>

                        <Svg
                          className={`w-4 text-text ${isOpen ? '' : 'rotate-180'}`}
                          src="/svgs/chevron-down.svg#chevron-down"
                          alt="Open Menu Icon"
                          title="Chevron"
                          viewBox="0 0 24 24"
                        />
                      </button>
                      <ul 
                        id={`${textToHandle(link?.text)}-main-menu`} 
                        role="menu"
                        aria-label={`${link?.text} collections`}
                        className={`bg-offWhite p-5 gap-2 mt-[.5px] ${isOpen ? 'hidden' : 'flex flex-col'}`}
                      >
                        {links?.map(({ link }, index) => {
                          return (
                            <li role="menuitem" key={index}>
                              <Link
                                aria-label={
                                  link?.newTab 
                                    ? `Open in a new tab to view ${link?.text}` 
                                    : link?.text
                                }
                                href={link?.url}
                                newTab={link?.newTab}
                                onClick={handleCloseSidebar}
                                type={link?.type}
                              >
                                {link?.text}
                              </Link>
                            </li>
                          );
                        })}
                        {link?.url && (
                          <li>
                            <Link
                              className="text-link-sm"
                              aria-label={
                                link?.newTab 
                                  ? `Open in a new tab to view ${link?.text}` 
                                  : link?.text
                              }
                              href={link?.url}
                              newTab={link?.newTab}
                              onClick={handleCloseSidebar}
                              type={link?.type}
                            >
                              {link?.text}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </>
                  ) : (
                    <Link
                      aria-label={
                        link?.newTab 
                          ? `Open in a new tab to view ${link?.text}` 
                          : link?.text
                      }
                      className="text-nav flex h-14 w-full items-center p-5"
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                      type={link?.type}
                    >
                      {link?.text}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>

          {mainLink?.text && (
            <div className="px-5">
              <Link
                aria-label={
                  mainLink?.newTab 
                    ? `Open in a new tab to view ${mainLink?.text}` 
                    : mainLink?.text
                }
                className="w-full btn-primary mb-8"
                href={mainLink.url}
                newTab={mainLink.newTab}
                onClick={handleCloseSidebar}
                type={mainLink.type}
              >
                {mainLink.text}
              </Link>
            </div>
          )}

          {imageLinks?.length > 0 && (
            <ul className="mb-8 flex flex-col gap-5">
              {imageLinks.map(({ alt, caption, image, link }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-label={
                        link?.newTab 
                          ? `Open in a new tab to view ${caption}` 
                          : caption
                      }
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                      type={link?.type}
                    >
                      <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                        {image?.src && (
                          <Image
                            alt={alt}
                            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                            crop="center"
                            height={Math.floor(768 / (16 / 9))}
                            src={image.src}
                            width="768"
                          />
                        )}
                      </div>

                      <p className="mt-3 text-sm">{caption}</p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
