import { useState } from 'react';

import { Menu } from './Menu';
import { MenuDrawer } from './MenuDrawer';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { usePromobar } from '../../hooks';
import { useMenuDrawer } from './useMenuDrawer';
import { useMenuSidebar } from './useMenuSidebar';

export function Header() {
  const {
    state: { menuDrawerContent },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  } = useMenuDrawer();
  const { promobarDisabled } = usePromobar();

  const {
    state: { menuSidebarOpen, nestedSidebarContent },
    actions: { handleCloseSidebar, handleNestedSidebar, handleOpenSidebar },
    refs: { menuRef, buttonRef },
  } = useMenuSidebar();

  const [promobarHidden, setPromobarHidden] = useState(false);

  return (
    <header
      className={`fixed right-0 top-0 left-0 z-10 flex flex-col bg-white transition-[height] duration-300 ease-out ${
        promobarHidden || promobarDisabled
          ? 'h-[var(--menu-height)] lg:h-[var(--header-height)]'
          : 'h-[calc(var(--menu-height)+var(--promobar-height))] lg:h-[calc(var(--header-height)+var(--promobar-height))]'
      }`}
    >
      <Promobar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromobarHidden}
      />

      <Menu
        handleOpenSidebar={handleOpenSidebar}
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
        menuSidebarOpen={menuSidebarOpen}
        buttonRef={buttonRef}
      />

      <MenuDrawer
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
      />

      <MenuSidebar
        handleCloseSidebar={handleCloseSidebar}
        handleNestedSidebar={handleNestedSidebar}
        menuSidebarOpen={menuSidebarOpen}
        nestedSidebarContent={nestedSidebarContent}
        menuRef={menuRef}
      />
    </header>
  );
}

Header.displayName = 'Header';
