import { useEffect } from 'react';

import { A11y, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { MenuNestedSidebar } from './MenuNestedSidebar';
import { ProductItem } from '../ProductItem';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  nestedSidebarContent,
  handleNestedSidebar,
  menuRef,
}) {
  const customer = useCustomer();
  const settings = useSettings();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();

  const {
    links: additionalLinks,
    menuItems,
    productsSlider,
  } = { ...settings?.header?.menu };
  const { products, heading: productsHeading } = {
    ...productsSlider,
  };

  const textToHandle = (text) => {
    if (!text) return '';
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
  };

  useEffect(() => {
    // Event listener for "Escape" key press
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseSidebar();
      }
    };
    if (menuSidebarOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuSidebarOpen, handleCloseSidebar]);

  return (
    <div
      className={`fixed left-0 top-0 z-[60] h-full w-full lg:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute top-0 left-0 hidden h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-full flex-col overflow-hidden bg-white transition md:max-w-[var(--sidebar-width)] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
        aria-hidden={!menuSidebarOpen}
        id="mobile-menu" 
        role="menu"
      >
        {menuSidebarOpen && (
          <>
            <div className="h-[var(--menu-height)] relative flex items-center shadow-hairline-b bg-white transition">
              <div className="h-full flex items-center pl-5">
                <button
                  aria-label="Close menu sidebar"
                  className="w-6"
                  onClick={handleCloseSidebar}
                  type="button"
                >
                  <Svg
                    className="w-full text-text"
                    src="/svgs/close.svg#close"
                    alt="Close Icon"
                    title="Close"
                    viewBox="0 0 32 32"
                  />
                </button>
              </div>
              
              <div className="flex items-center mx-4">
                <Link 
                  aria-label="Go to homepage"
                  href="/"
                  onClick={handleCloseSidebar}
                >
                  <Svg
                    className="text-text"
                    src="/svgs/logo-mobile.svg#logo-mobile"
                    alt="Rhoback Logo"
                    title="Rhoback"
                    viewBox="0 0 128 30"
                    width="128px"
                    height="30px"
                  />
                </Link>
              </div>
              <div className="flex items-center justify-end gap-2 ml-auto mr-5">
                <button
                  aria-label="Open search sidebar"
                  className="w-5"
                  onClick={() => {
                    handleCloseSidebar();
                    openSearch();
                  }}
                  type="button"
                >
                  <Svg
                    className="w-full text-text"
                    src="/svgs/search.svg#search"
                    title="Search"
                    viewBox="0 0 32 32"
                  />
                </button>
                <Link
                  aria-label="Go to account page"
                  href={customer ? '/account/orders' : '/account/login'}
                >
                  <Svg
                    className="w-5 text-text"
                    src="/svgs/account.svg#account"
                    alt="Open Account Icon"
                    title="Account"
                    viewBox="0 0 32 32"
                  />
                </Link>

                <div className="relative flex items-center">
                  <button
                    aria-label="Open cart"
                    className="w-5"
                    onClick={() => {
                      handleCloseSidebar();
                      openCart();
                    }}
                    type="button"
                  >
                    <Svg
                      className="w-full text-text"
                      src="/svgs/cart.svg#cart"
                      alt="Open Cart Icon"
                      title="Cart"
                      viewBox="0 0 32 32"
                    />
                  </button>

                  <p className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
                    ({cartCount || 0})
                  </p>
                </div>
              </div>
            </div>

            <div className="relative w-full flex-1 overflow-x-hidden">
              <div
                className={`scrollbar-hide h-full w-full overflow-y-auto ${
                  nestedSidebarContent ? 'invisible' : 'visible'
                }`}
              >
                <nav className="mb-5 flex" ref={menuRef}>
                  <ul className="w-full flex-col" aria-label="Navigation menu list" tabIndex="-1">
                    {menuItems?.map((item, index) => {
                      const hasContent =
                        item.links?.length > 0 || item.imageLinks?.length > 0;

                      return (
                        <li
                          key={index}
                          className="flex min-h-14 w-full shadow-hairline-b"
                          role="menuitem"
                        >
                          {hasContent ? (
                            <button
                              aria-label={`${item.menuItem.text} main menu`}
                              aria-expanded={nestedSidebarContent ? 'true' : 'false'}
                              aria-controls={`${textToHandle(item.menuItem.text)}-main-menu`}
                              type="button"
                              className="flex h-14 w-full items-center justify-between gap-5 p-5"
                              onClick={() => handleNestedSidebar(index)}
                            >
                              <p className="text-nav flex-1 text-left">
                                {item.menuItem.text}
                              </p>

                              <Svg
                                className="w-4 text-text"
                                src="/svgs/chevron-right.svg#chevron-right"
                                alt="Open Menu Icon"
                                title="Chevron"
                                viewBox="0 0 24 24"
                              />
                            </button>
                          ) : (
                            <Link
                              aria-label={
                                item.menuItem.newTab
                                  ? `Open in a new tab to view ${item.menuItem.text}`
                                  : item.menuItem.text
                              }
                              className="text-nav flex h-14 w-full items-center p-5"
                              href={item.menuItem.url}
                              onClick={handleCloseSidebar}
                              newTab={item.menuItem.newTab}
                              type={item.menuItem.type}
                            >
                              {item.menuItem.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                {products?.length > 0 && (
                  <div className="mb-5">
                    <h3 className="text-title-h5 mb-2 px-4">
                      {productsHeading}
                    </h3>

                    <Swiper
                      modules={[A11y, Keyboard]}
                      a11y={{ enabled: true }}
                      slidesPerView={1.3}
                      spaceBetween={16}
                      slidesOffsetBefore={16}
                      slidesOffsetAfter={16}
                      grabCursor
                      className="mb-5"
                      role="region"
                      aria-label={`${productsHeading} Slider`}
                      tabIndex="0"
                      keyboard={{
                        enabled: true,
                        onlyInViewport: true,
                      }}
                    >
                      {products.map(({ product }, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ProductItem
                              handle={product.handle}
                              index={index}
                              onClick={handleCloseSidebar}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                )}

                {additionalLinks?.length > 0 && (
                  <ul className="mb-8 flex flex-col gap-[5px] px-5">
                    {additionalLinks.map(({ link }, index) => {
                      return (
                        <li key={index}>
                          <Link
                            aria-label={
                              link?.newTab 
                                ? `Open in a new tab to view ${link?.text}` 
                                : link?.text
                            }
                            href={link?.url}
                            onClick={handleCloseSidebar}
                            newTab={link?.newTab}
                            type={link?.type}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>

              <MenuNestedSidebar
                handleCloseSidebar={handleCloseSidebar}
                handleNestedSidebar={handleNestedSidebar}
                nestedSidebarContent={nestedSidebarContent}
                textToHandle={textToHandle}
              />
            </div>
          </>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
