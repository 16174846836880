import { useEffect } from 'react';

import { Schema } from './Iframe.schema';

export function Iframe({ cms }) {
  const { section, iframe } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  // Function to manually initialize the new script
  const initializeScript = () => {
    // Assuming the new script has an init function or similar
    // Replace this with the actual initialization code if needed
    if (window.vsScriptInit) {
      window.vsScriptInit();
    }
  };

  useEffect(() => {
    const scriptId = 'vsScript';
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = iframe?.url;
    script.async = true;
    script.onload = () => {
      document.dispatchEvent(new Event('vsScriptLoaded'));
    };

    document.body.appendChild(script);

    // Ensure that the script global object and init function are available
    if (window.vsScriptInit) {
      initializeScript();
    } else {
      // Listen for the script to load then initialize
      document.addEventListener('vsScriptLoaded', initializeScript);
    }

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('vsScriptLoaded', initializeScript);
    };
  }, []); // Dependency array is empty to run only once per mount

  return (
    <>
      <div
        className={`flex justify-center ${section?.fullBleed ? '' : 'px-contained'} ${
          section?.verticalPadding ? 'py-contained' : ''
        }`}
      >
        <div
          className={`relative ${maxWidthContainerClass}`}
        >
          <div id="vsscript_197105_535685"></div>
        </div>
      </div>    
    </>
  )
}

Iframe.displayName = 'Iframe';
Iframe.Schema = Schema;